<template>
  <el-container>
    <el-drawer
        ref="sideMenu"
        size="auto !important;min-width: 30% !important;max-width: 70%;"
        :visible.sync="showSideMenu"
        direction="ltr"
        :with-header="false" v-if="$isMobile">
      <el-aside width="" class="mobile el-menu-vertical rel hide" style="background-color: #304156;padding-top:60px;" :style="$isMobile ? '' : 'box-shadow: 5px 0px 5px 0px rgba(0,0,0,.3)'">
        <div style="height: 60px;line-height:60px;top:0;width: 100%;z-index: 2;" class="cl padding text-center abs">
          <div class="f-b light f30">油库</div>
        </div>
        <m-side-menu style="z-index: 1;" ref="sidemenu"/>
      </el-aside>
    </el-drawer>
    <el-aside width="" class="el-menu-vertical rel hide" :class="showSideMenu ? 'menu-collapse' : ''" style="background-color: #304156;padding-top:60px;box-shadow: 5px 0px 5px 0px rgba(0,0,0,.3)" v-else>
      <div style="height: 60px;line-height:60px;top:0;width: 100%;z-index: 2;" class="cl padding text-center abs">
        <div class="f-b light f30" style="height: 60px;overflow-y: hidden;">洪山产业地图</div>
      </div>
      <m-side-menu :side-collapse="showSideMenu" style="z-index: 1;" ref="sidemenu"/>
    </el-aside>
    <el-container>
      <el-header class="border-bottom hide" style="text-align: right; font-size: 12px;line-height: 60px;background:#fff;">
        <div class="cl padding-lr-20">
          <div class="z">
                        <span class="nav-item vm" @click="toggleSide">
                            <i class="f20 hand" :class="showSideMenu ? 'el-icon-s-unfold' : 'el-icon-s-fold'" style="vertical-align: middle;"/>
                        </span>
            <span class="vm border-left margin-left-20 padding-left-20 f16 dark-2" style="line-height: 30px;">{{title}}</span>
          </div>
          <div class="y text-right" style="line-height: 60px;" v-if="!$isMobile">
            <el-menu
                style="border-bottom: 0 none !important;"
                mode="horizontal"
                text-color="#333"
                active-text-color="#ffd04b">
              <el-menu-item>{{$user.getUserName()}}</el-menu-item>
              <el-menu-item class="hover-bg-blue-0" @click="$router.push({path:'/changePass'})">修改密码</el-menu-item>
              <el-menu-item class="hover-bg-blue-0" @click="quit">退出</el-menu-item>
<!--              <el-menu-item class="hover-bg-blue-0" @click="showDrawer"><i class="el-icon-setting"></i></el-menu-item>-->
            </el-menu>
          </div>
        </div>
      </el-header>
      <div class="padding-10 bg-light" v-if="!$isMobile">
        <div class="no-wrap w100" style="overflow-y: hidden;overflow-x: auto;">
          <el-tag class="margin-right hand" :closable="item.path !== '/dashboard'" v-for="(item,index) in tabsList" @click="tabClick(item)" :effect="$route.path === item.path ? 'dark' : 'plain'" @close="removeTab(item)">{{item.name}}</el-tag>
        </div>
      </div>
      <router-view :key="$route.fullPath"/>
    </el-container>
    <el-drawer
        size="300px"
        title="设置"
        :visible.sync="drawer"
        direction="rtl">
      <div class="padding-20">
        <theme-color-choose @success="themeSuccess"></theme-color-choose>
      </div>
    </el-drawer>
    <theme-color-choose is-dom></theme-color-choose>
  </el-container>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      showSideMenu: false,
      tabsList:[],
      tabActive:'',
      title:'首页',
      drawer: false,
    };
  },
  created() {
    document.querySelector('body').style.backgroundImage = '';
  },
  mounted() {
    this.initTab();
    this.getTitle();
  },
  methods: {
    themeSuccess(){
      console.log('===themeSuccess===');
    },
    showDrawer(){
      this.drawer = true;
    },
    toggleSide() {
      this.showSideMenu = !this.showSideMenu;
    },
    quit() {
      this.$confirm('是否退出登录？', '提示', {
        confirmButtonText: '立即退出',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$utils.sessionClear();
        this.$router.replace({ path: "/login" });
      }).catch(() => {

      });
    },

    //初始化tab
    initTab(){
      this.tabsList = this.$user.getTab();
    },

    //切换tab
    tabClick(e){
      this.$router.push({path:e.path,query:e.query ? {...e.query} : {}});
    },

    //移除tab
    removeTab(e){
      this.$user.removeTab(e.path);

      this.initTab();
      // let _path = e.path;
      //
      // let _last = this.$user.delTab(_path);
      // let _tabs = this.$user.getTab();
      //
      // let _tabsKeys = Object.keys(_tabs);
      //
      // this.initTab();
      //
      // if(_path === this.$route.path){
      //   if(_tabsKeys.length > 1){
      //     this.$router.replace({path:_tabs[_tabsKeys[_tabsKeys.length - 1]].path});
      //   }else{
      //     this.$router.replace({path:'/dashboard'});
      //   }
      // }
      // this.$router.replace({path:_path === this.$route.path ? (_tabsKeys.length > 1 ? _last : '/dashboard') : null});
    },

    //获取当前路由的title
    getTitle(){
      // if(this.$route.path === '/dashboard'){
      //
      // }else {
      //     let _config = window.config.paths();
      //     let _title = _config[this.$route.path].name;
      //
      //     this.title = _title ? _title : '';
      // }
      this.title = this.$route.meta.title ? this.$route.meta.title : '';
    }
  },
  watch:{
    $route(to,from){

      this.initTab();

      this.getTitle();


      if(to.path !== from.path && this.$isMobile){
        this.$refs.sideMenu.closeDrawer();
      }
    }
  }
  // computed: {
  // tabs () {
  //     return this.$store.state.tabs
  // }
  // }
};
</script>
